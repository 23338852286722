import React from 'react';
import Notifications from '../src';
import pkg from '../package.json';
import { namespace } from './configs/projectNames';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = props => {
  const shellProps = window.Shell || {};
  return (
    <section id={pkg.name} className={namespace}>
      <Notifications shell={shellProps} {...props} />
    </section>
  );
};

export default Root;
