import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  ${props => (props.fullScreen
    ? `height: 100%;
       width: 100%;`
    : `flex: 1;
       min-height: 100px;
  `)
}
`;

export default Container;
