import styled from 'styled-components';
import { Card as VeneerCard } from '@veneer/core';

export const Content = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }
`;

export const Card = styled(VeneerCard)`
  height: 419px;
  width: 100%;
`;

export default Content;
