import React from 'react';
import { ProgressIndicator } from '@veneer/core';
import PropTypes from 'prop-types';
import { Container } from './styles';

const LoaderWidget = ({ fullScreen }) => (
  <Container fullScreen={fullScreen}>
    <ProgressIndicator />
  </Container>
);

LoaderWidget.defaultProps = {
  fullScreen: false,
};

LoaderWidget.propTypes = {
  fullScreen: PropTypes.bool,
};

export default LoaderWidget;
